import React, { useState } from "react";

import { FiSearch } from "react-icons/fi";
import { GrAdd } from "react-icons/gr";
import { BiLoaderAlt } from "react-icons/bi";
import { isMobile } from "react-device-detect";

// You need to import the CSS only once
// import "react-awesome-lightbox/build/style.css";

import { Link, useNavigate } from "react-router-dom";
import {
  setCartLeng,
  setFeachers,
  useGetProductDetailQuery,
  useSetCartMutation,
} from "../../../products/productSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ImgesData } from "../../proImgs/ImgesData";
import { useTranslation } from "react-i18next";
import { CustomToaster } from "../../../../common/toaster/CustomToaster";
import Brands from "../../brands/Brands";
import Slider from "react-slick";

function FeaturedProduct({ isLoading, data, error, handleShow, isPopular }) {
  const curr = window.localStorage.getItem("currencySym");
  const currencySymbol = curr ? curr : "QAR";
  const dispacher = useDispatch();

  const [slidesToShow, setSlidesToShow] = useState(3);

  useEffect(() => {
    dispacher(setFeachers(data));
    setSlidesToShow(data?.length);
  }, [data]);

  const [count, setCount] = useState(12);
  const changeLoad = () => {
    setCount(count + 4);
  };
  const { t, i18n } = useTranslation();

  const [
    addToCart,
    {
      data: datacart,
      isLoading: isAddCartLoading,
      isSuccess: isAddToCartSuccess,
      isError: isAddToCartError,
    },
  ] = useSetCartMutation();
  const [names, setNames] = useState("");
  const handleAddCart = (item) => {
    setNames(item.name);
    const payload = {
      qty: 1,
      pickupPoint: null,
      variantId: item.variations[0]?.uid,
      productId: item.uid,
      deliveryType: "HOME DELIVERY",
      seller_id: item.variations[0]?.prices?.seller_id,
      sku: item.variations[0]?.prices?.sku,
    };
    addToCart(payload);
  };
  const [showTaoster, setShowToaster] = useState({
    show: false,
    message: "",
    color: "success",
  });
  useEffect(() => {
    if (isAddToCartSuccess) {
      dispacher(setCartLeng(datacart?.cartLength));
      window.localStorage.setItem("cartItem", datacart?.cart);
      setShowToaster({
        show: true,
        message: "Product added successfully!",
        color: "success",
      });
    }
    if (isAddToCartError) {
      setShowToaster({
        show: true,
        message: "Something went wrong Product Not Add",
        color: "danger",
      });
    }
  }, [isAddToCartSuccess, isAddToCartError]);

  const handleToaster = () => {
    setShowToaster({ ...showTaoster, show: false });
  };

  // Buy Now
  const navigate = useNavigate();


  const [
    addToCartBuy,
    {
      data: datacartBuy,
      isLoading: isAddCartLoadingBuy,
      isSuccess: isAddToCartSuccessBuy,
      isError: isAddToCartErrorBuy,
    },
  ] = useSetCartMutation();
  useEffect(() => {
    if (isAddToCartSuccessBuy) {
      dispacher(setCartLeng(datacartBuy?.cartLength));
      window.localStorage.setItem("cartItem", datacartBuy?.cart);
      setShowToaster({
        show: true,
        message: "Product added successfully!",
        color: "success",
      });
      setTimeout(() => {
        navigate("/checkout");
      }, 1000);
    }
  }, [isAddToCartSuccessBuy])
  const BuyNowItem = (item) => {
    setNames(item.name);
    const payload = {
      qty: 1,
      pickupPoint: null,
      variantId: item.variations[0]?.uid,
      productId: item.uid,
      deliveryType: "HOME DELIVERY",
      seller_id: item.variations[0]?.prices?.seller_id,
      sku: item.variations[0]?.prices?.sku,
    };
    addToCartBuy(payload);


  };


  const settings = {
    speed: 500,
    slidesToShow: slidesToShow > 3 ? 4 : slidesToShow, // Number of items to show on desktop
    slidesToScroll: 1,
    infinite: true,

    responsive: [
      {
        breakpoint: 1024, // Screen size for tablets
        settings: {
          slidesToShow: slidesToShow > 3 ? 4 : slidesToShow, // Number of items to show on tablets
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 768, // Screen size for mobile devices
        settings: {
          slidesToShow: slidesToShow > 1 ? 2 : slidesToShow, // Number of items to show on mobile
          slidesToScroll: 1,
          infinite: true,
          // arrows: false,
        },
      },
    ],
  };

  return (
    <>
      <CustomToaster
        color={showTaoster.color}
        title={names}
        show={showTaoster.show}
        setShow={handleToaster}
        message={showTaoster.message}
        position="bottom-end"
        delay={10000}
      />
      {isLoading && (
        <div className="loaderIcon">
          <BiLoaderAlt />
        </div>
      )}
      {isPopular ? (
        <div className="categoryWrapper">
          <Slider {...settings}>
            {data?.map((item, i) => {
              return (
                <React.Fragment key={item._id}>
                  <div
                    className="cusname"
                    style={
                      isMobile ? { margin: "0 2px" } : { margin: "0 10px" }
                    }
                  >
                    <div className="featuredInfo">
                      <div className="featuredFigure">
                        <div className="featuredImg">
                          {/* <Link to={`/product/${item._id}`}> */}

                          <Link to={`/product/${item?.uid}/${item.slug}`}>
                            <img
                              src={
                                item?.variations[0]?.mainImage_url
                                  ? item.variations[0]?.mainImage_url?.url
                                  : item.variations[0]?.mainImage_url?.url
                              }
                              alt="Product"
                              className="imgProduct"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="featuredContent">
                        <h6>category</h6>
                        <h5>
                          <Link to={`/product/${item?.uid}/${item.slug}`}>
                            {item.name}
                          </Link>
                        </h5>

                        <div className="rateDigit">
                          <span className="currentPrice">
                            {" "}
                            {
                              item?.variations[0]?.prices?.country_id
                                ?.currency_id?.symbol
                            }
                            :{item?.variations[0]?.prices?.sale_rate}
                          </span>
                          {/* <span className="currentPrice">{currencySymbol} {item?.variations[0]?.sale_rate}</span> */}
                        </div>

                        <div className="buyNowInfo">
                          <Link
                            className="btn btn-danger buyNow"
                            to={`#`}
                            onClick={() => {
                              BuyNowItem(item);
                            }}
                          // to={`/product/${item._id}`}
                          >
                            Buy Now
                          </Link>

                          <Link
                            to={`#`}
                            onClick={() => handleAddCart(item)}
                            // to={`/product/${item?.uid}/${item.slug}`}
                            className="buyNow"
                          >
                            {t("Add to Cart")}{" "}
                            <i className="ri-arrow-right-up-fill"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  {i === 7 && <Brands />}
                </React.Fragment>
              );
            })}
          </Slider>
        </div>
      ) : (
        data &&
        data?.slice(0, count).map((item, i) => {
          return (
            <React.Fragment key={item._id}>
              <div className="col-lg-3 col-md-6 col-sm-12 cusname">
                <div className="featuredInfo">
                  <div className="featuredFigure">
                    <div className="featuredImg">
                      {/* <Link to={`/product/${item._id}`}> */}

                      <Link to={`/product/${item?.uid}/${item.slug}`}>
                        <img
                          src={
                            item?.variations[0]?.mainImage_url
                              ? item.variations[0]?.mainImage_url?.url
                              : item.variations[0]?.mainImage_url?.url
                          }
                          alt="Product"
                          className="imgProduct"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="featuredContent">
                    <h6>category</h6>
                    <h5>
                      <Link to={`/product/${item?.uid}/${item.slug}`}>
                        {item.name}
                      </Link>
                    </h5>

                    <div className="rateDigit">
                      <span className="currentPrice">
                        {" "}
                        {
                          item?.variations[0]?.prices?.country_id?.currency_id
                            ?.symbol
                        }
                        :{item?.variations[0]?.prices?.sale_rate}
                      </span>
                      {/* <span className="currentPrice">{currencySymbol} {item?.variations[0]?.sale_rate}</span> */}
                    </div>

                    <div className="buyNowInfo">
                      <Link
                        className="btn btn-danger buyNow"
                        to={`#`}
                        onClick={() => {
                          BuyNowItem(item);
                        }}
                      // to={`/product/${item._id}`}
                      >
                        Buy Now
                      </Link>

                      <Link
                        to={`#`}
                        onClick={() => handleAddCart(item)}
                        // to={`/product/${item?.uid}/${item.slug}`}
                        className="buyNow"
                      >
                        {t("Add to Cart")}{" "}
                        <i className="ri-arrow-right-up-fill"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {i === 7 && <Brands />}
            </React.Fragment>
          );
        })
      )}

      {!isPopular && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            type="button"
            onClick={changeLoad}
            className="btn btn-info"
            disabled={count > data?.length}
          >
            {t("Load More")}
          </button>
        </div>
      )}

      {error && (
        <div className="alertMsg mb-4" role="alert">
          No Data Found
        </div>
      )}
    </>
  );
}

export default FeaturedProduct;
