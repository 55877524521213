import React, { useEffect, useState } from "react";

import etg from "../../../assets/img/brands/etg.png";
import kynoch from "../../../assets/img/brands/kynoch.png";
import falcon from "../../../assets/img/brands/falcon.png";
import oemff from "../../../assets/img/brands/oemff.png";
import "./Brands.css";
import { Link } from "react-router-dom";
import { useListAllBrandQuery } from "../../products/productSlice";
import axios from "axios";
import { AiFillCar } from "react-icons/ai";
import { RiArrowGoForwardLine } from "react-icons/ri";
import { FaMoneyBill } from "react-icons/fa";
import { BsShieldLockFill } from "react-icons/bs";

const imgs = [etg, kynoch, falcon, oemff]

function Brands() {
  // const { data, isLoading, error } = useListAllBrandQuery()
  const [dohaCost, setDohaCost] = useState(0);
  const [outsideDohaCost, setOutsideDohaCost] = useState(0);
  const getShiping = async (id) => {
    const res = await axios.get(`https://qar-3324.onlineparttimejobs.in/api/shippingPrice/public`);
    const res2 = await axios.get(`https://qar-3324.onlineparttimejobs.in/api/shippingSetting/public`);
   
    for (let i = 0; i < res.data.length; i++) {
      const element = res.data[i].area.toLowerCase();
      if (element !== "doha") {
      //  setDohaCost(res.data[i].price);
      } else {
        setOutsideDohaCost(res.data[i].price);

      }
    }
    setDohaCost(res2.data?.setting?.minPrice);
  }
  useEffect(()=>{
    getShiping()
  },[])

  return (
    <>
      <section className="brandsSec">
        <div className="container">

          <div className="row mainRap">
            <div className="col-lg-3">
              <div>
                <AiFillCar />
              </div>
              <div>
                <h6>Free Shipping</h6>
                <p>FREE DELIVERY FOR ORDERS <br></br> ABOVE {dohaCost} <br></br>
                {outsideDohaCost} QAR DELIVERY INSIDE DOHA
                </p>
              </div>
            </div>
            <div className="col-lg-3">
              <div>
                <RiArrowGoForwardLine />
              </div>
              <div>
                <h6>Full Refund</h6>
                <p>Didn't like it?. No problem! simply
                  return it within 7 days and get a full
                  refund.</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div>
                <FaMoneyBill />
              </div>
              <div>
                <h6>Debit & Credit Card</h6>
                <p>Don't have a Credit Card? We accept any
                  debit card issued in Qatar.</p>
              </div>
            </div>
            <div className="col-lg-3">
              <div>
                <BsShieldLockFill />
              </div>
              <div>
                <h6>100% Secure Payment</h6>
                <p>We ensure 100% secure payment
                  through accredited 3rd party.</p>
              </div>
            </div>

          </div>

        </div>
      </section>
    </>
  );
}

export default Brands;
